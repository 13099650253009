import { useParams } from "react-router-dom";
import "./style.css";
import { Container } from "react-bootstrap";
import { Image } from 'antd';
import { useEffect, useState } from "react";
import { TbRubberStamp, TbRulerMeasure } from "react-icons/tb";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import SizeChart from "../../Assets/SizeChart/sizeChart.jpeg"
import { ImCross } from "react-icons/im";
import React from 'react';
import { InputNumber } from 'antd';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Carousel } from 'antd';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Button, Navbar, Footer } from "../../Components";
import axios from "axios";
import Swal from "sweetalert2";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
};


const CustomPrevArrow = ({ onClick }) => (
    <div className="product-carousel-arrows left-arrow" onClick={onClick}>
        <IoIosArrowBack />
    </div>
);

const CustomNextArrow = ({ onClick }) => (
    <div className="product-carousel-arrows right-arrow" onClick={onClick}>
        <IoIosArrowForward />
    </div>
);

const Products = ({ setShowSpinner }) => {
    const [item, setItem] = useState({});
    const { id } = useParams();

    const getSpecificItem = (id) => {
        setShowSpinner(true);
        const url = `https://ecommerce-server-use0.onrender.com/api/item/one/${id}`
        axios.get(url)
            .then(res => {
                const item = res.data.item;
                setItem(item)
                setMainImgUrl(item.imgUrl[0])
                for (let v in item.sizes) {
                    if (item.sizes[v]) {
                        setSize(`${v}`)
                        break
                    }
                }
                setShowSpinner(false);
            })
            .catch(error => {
                // Error handler
                if (error.response) {
                    console.log('Error response:', error.response.data);
                } else if (error.request) {
                    console.log('Error request:', error.request);
                } else {
                    console.log('Error message:', error.message);
                }
            });
    }

    useEffect(() => {
        getSpecificItem(id);
    }, [id]);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [size, setSize] = useState("");
    const [quantity, setQuantity] = useState(1);

    const [mainImgUrl, setMainImgUrl] = useState(item.imgUrl && item.imgUrl[0]);

    const handleAddToCart = (item, quantity, size) => {
        setShowSpinner(true);
        const cartItem = {
            quantity,
            size,
            item: item,
            totalPrice: item.price * quantity,
        }
        if (localStorage.getItem('cart')) {
            const cartPreviousData = JSON.parse(localStorage.getItem('cart'));
            console.log(cartPreviousData);
            localStorage.setItem("cart", JSON.stringify([...cartPreviousData, cartItem]));
        } else {
            localStorage.setItem("cart", JSON.stringify([cartItem]));
        }
        setShowSpinner(false);
        Swal.fire({
            icon: 'success',
            title: 'Added..',
            text: 'Item Has Been Added to Cart'
        })
    };

    return (
        <>
            <Navbar />
            <div>
                <Container className="products-div-container">
                    <div className="img-div-container">
                        {item.imgUrl && item.imgUrl.map((v, i) => {
                            return (
                                <div className={`img-div ${i !== 0 ? 'mt-2' : ''}`} key={i}>
                                    <img
                                        src={v.url}
                                        alt={v.public_id}
                                        onClick={() => setMainImgUrl(v)}
                                    />
                                </div>
                            )
                        })}
                    </div>
                    <div className="main-img-div">
                        <Image
                            width={'100%'}
                            height={'100%'}
                            src={mainImgUrl?.url}
                            alt={mainImgUrl?.public_id}
                        />
                    </div>
                    <Carousel
                        className="product-carousel-div"
                        infinite={true}
                        autoplay={true}
                        fade={true}
                        autoplaySpeed={4000}
                        arrows
                        prevArrow={<CustomPrevArrow />}
                        nextArrow={<CustomNextArrow />}
                    >
                        {item.imgUrl && item.imgUrl.map((v, i) => {
                            return (
                                <div className="product-carousel-slide" key={i}>
                                    <img
                                        src={v?.url}
                                        alt={v?.public_id}
                                    />
                                </div>
                            )
                        })}
                    </Carousel>
                    <div className="product-detail-div">
                        <h1>{item.name}</h1>
                        <h4>Rs {item.price && item.price.toLocaleString('en-PK')}</h4>
                        {
                            item.discountPercentage > 0 ?
                                <h6>
                                    <span>Rs {item.priceBeforeDiscount && item.priceBeforeDiscount.toLocaleString('en-PK')}</span> -{item.discountPercentage}%
                                </h6>
                                :
                                <></>
                        }
                        <p>SKU : {item._id}</p>
                        <h5>size</h5>
                        <div className="d-flex mb-3">
                            {item.sizes ? item.sizes.S && <span
                                className={size === 'S' ? 'active-size' : ''}
                                onClick={() => setSize('S')}
                            >
                                S
                            </span> : ""}
                            {item.sizes ? item.sizes.M && <span
                                className={size === 'M' ? 'active-size' : ''}
                                onClick={() => setSize('M')}
                            >M
                            </span> : ""}
                            {item.sizes ? item.sizes.L && <span
                                className={size === 'L' ? 'active-size' : ''}
                                onClick={() => setSize('L')}
                            >L
                            </span> : ""}
                            {item.sizes ? item.sizes.Xl && <span
                                className={size === 'Xl' ? 'active-size' : ''}
                                onClick={() => setSize('Xl')}
                            >Xl
                            </span> : ""}
                        </div>
                        <span onClick={handleOpen}>
                            Size Chart
                            <TbRulerMeasure className="ms-2" />
                        </span>
                        <div className="add-to-cart-btn-div mt-4">
                            <InputNumber
                                min={1}
                                max={50}
                                value={quantity}
                                onChange={(e) => setQuantity(e)}
                                className="quanitity-input"
                            />
                            <Button addclass="add-to-cart-btn" title="Add To Cart" onClick={() => {
                                if (item.status) {
                                    handleAddToCart(item, quantity, size)
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Out of Stock...",
                                        text: "This item is currently out of stock",
                                    });

                                }
                            }} />
                        </div>
                        <div className="mt-4">
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography>Product Description</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {item.description}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography>Product Specifications</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {item.specification}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    <Typography>Care Instructions</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {item.careInstruction}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    <Typography>Shipping & Return</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {item.shippingAndReturn}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="final-card-div mt-4">
                            <div className="final-img-div">
                                <img src={item.imgUrl && item.imgUrl[0].url} alt={item.imgUrl && item.imgUrl[0].public_id} />
                            </div>
                            <div className="final-details-div">
                                <div>

                                    <h4>EVELYN OFFWHITE</h4>
                                    <div className="mb-2">
                                        <span>
                                            <strong className="me-2">
                                                Size
                                            </strong>
                                            "{size}"
                                        </span>
                                        <br />
                                        <span>
                                            <strong className="me-2">
                                                Quantity
                                            </strong>
                                            "{quantity}"
                                        </span>
                                    </div>
                                </div>
                                <Button title='Add To cart' onClick={() => {
                                    if (item.status) {
                                        handleAddToCart(item, quantity, size)
                                    } else {
                                        Swal.fire({
                                            icon: "error",
                                            title: "Out of Stock...",
                                            text: "This item is currently out of stock",
                                        });

                                    }
                                }} />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <ImCross onClick={handleClose} className="modal-close-icon" />
                        <h2>Trouser</h2>
                        <table style={{ width: '100%' }} border={1}>
                            <thead border={1}>
                                <tr style={{ width: '100%', border: '1px solid black' }}>
                                    <th style={{ backgroundColor: 'black', color: 'white' }}>
                                        Sizes
                                    </th>
                                    <th style={{ backgroundColor: 'black', color: 'white' }}>
                                        Length
                                    </th>
                                    <th style={{ backgroundColor: 'black', color: 'white' }}>
                                        Pancha
                                    </th>
                                    <th style={{ backgroundColor: 'black', color: 'white' }}>
                                        Bust
                                    </th>
                                    <th style={{ backgroundColor: 'black', color: 'white' }}>
                                        Thighs
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ['S', 'M', 'L', 'Xl'].map((v, i) => {
                                        return (
                                            <>
                                                {
                                                    item.sizes && item.sizes[v] &&
                                                    <tr key={i} style={{ width: '100%', textAlign: 'center' }}>
                                                        <td style={{ border: '1px solid black' }}>
                                                            {v}
                                                        </td>
                                                        <td style={{ border: '1px solid black' }}>
                                                            {item.sizeChartTrouser ? item.sizeChartTrouser[v].length : ''}
                                                        </td>
                                                        <td style={{ border: '1px solid black' }}>
                                                            {item.sizeChartTrouser ? item.sizeChartTrouser[v].pancha : ''}
                                                        </td>
                                                        <td style={{ border: '1px solid black' }}>
                                                            {item.sizeChartTrouser ? item.sizeChartTrouser[v].bust : ''}
                                                        </td>
                                                        <td style={{ border: '1px solid black' }}>
                                                            {item.sizeChartTrouser ? item.sizeChartTrouser[v].thighs : ''}
                                                        </td>
                                                    </tr >
                                                }
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <h2>Shirt</h2>
                        <table style={{ width: '100%' }} border={1}>
                            <thead>
                                <tr style={{ width: '100%', border: '1px solid black' }}>
                                    <th style={{ backgroundColor: 'black', color: 'white' }}>
                                        Sizes
                                    </th>
                                    <th style={{ backgroundColor: 'black', color: 'white' }}>
                                        Length
                                    </th>
                                    <th style={{ backgroundColor: 'black', color: 'white' }}>
                                        Sleeves Width
                                    </th>
                                    <th style={{ backgroundColor: 'black', color: 'white' }}>
                                        Sleeves Length
                                    </th>
                                    <th style={{ backgroundColor: 'black', color: 'white' }}>
                                        Chest
                                    </th>
                                    <th style={{ backgroundColor: 'black', color: 'white' }}>
                                        Arm hole
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ['S', 'M', 'L', 'Xl'].map((v, i) => {
                                        return (
                                            <>
                                                {
                                                    item.sizes && item.sizes[v] &&
                                                    <tr key={i} style={{ width: '100%', textAlign: 'center' }}>
                                                        <td style={{ border: '1px solid black' }}>
                                                            {v}
                                                        </td>
                                                        <td style={{ border: '1px solid black' }}>
                                                            {item.sizeChartShirt ? item.sizeChartShirt[v].length : ''}
                                                        </td>
                                                        <td style={{ border: '1px solid black' }}>
                                                            {item.sizeChartShirt ? item.sizeChartShirt[v].sleevesWidth : ''}
                                                        </td>
                                                        <td style={{ border: '1px solid black' }}>
                                                            {item.sizeChartShirt ? item.sizeChartShirt[v].sleevesLength : ''}
                                                        </td>
                                                        <td style={{ border: '1px solid black' }}>
                                                            {item.sizeChartShirt ? item.sizeChartShirt[v].chest : ''}
                                                        </td>
                                                        <td style={{ border: '1px solid black' }}>
                                                            {item.sizeChartShirt ? item.sizeChartShirt[v].armHole : ''}
                                                        </td>
                                                    </tr >
                                                }
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </Box>
                </Fade>
            </Modal>
            <Footer />
        </>
    )
};

export default Products;
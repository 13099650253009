import AboutSec from "../../Section/About";
import Hero from "../../Section/Hero";
// import Section2 from "../../Section/Section2";
import Section2 from "../../Section/Section2New";
import Section3 from "../../Section/Section3";
import { CarouselComp, Navbar, Footer, MoveToTop } from "../../Components";
import axios from "axios";
import { useEffect, useState } from "react";
import Testimonials from "../../Section/Testmonials";
import Swal from "sweetalert2";

const Home = ({ setShowSpinner }) => {
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [data, setData] = useState([]);
    const [newArrival, setNewArrival] = useState([]);

    const getCategoryName = (status) => {
        const url = `https://ecommerce-server-use0.onrender.com/api/category/${status}`;
        axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                setCategoryOptions(res.data.data);
            })
            .catch(error => {
                // Error handler
                if (error.response) {
                    console.log('Error response:', error.response.data);
                } else if (error.request) {
                    console.log('Error request:', error.request);
                } else {
                    console.log('Error message:', error.message);
                }
            });
    };

    const handleSearch = async (categoryName) => {
        let obj;
        if (categoryName === 'all') {
            obj = {
                filter: {}
            };
        } else {
            obj = {
                category: categoryName,
                filter: {}
            };

        }

        try {
            const response = await axios.post('https://ecommerce-server-use0.onrender.com/api/item/search', obj, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data.data;
        } catch (error) {
            // Error handler
            if (error.response) {
                console.log('Error response:', error.response.data);
            } else if (error.request) {
                console.log('Error request:', error.request);
            } else {
                console.log('Error message:', error.message);
            }
        }
    };

    const fetchAllData = async () => {
        setShowSpinner(true);
        // Create an array of Promises based on categoryOptions
        const promises = categoryOptions.map(category => handleSearch(category.name)); // Assuming category object has a 'name' property

        // Use Promise.all to handle all Promises
        try {
            const results = await Promise.all(promises);
            setData(results); // Store results in the main data array
        } catch (error) {
            // Error handler
            if (error.response) {
                console.log('Error response:', error.response.data);
            } else if (error.request) {
                console.log('Error request:', error.request);
            } else {
                console.log('Error message:', error.message);
            }
        } finally {
            setShowSpinner(false);
        }
    };

    useEffect(() => {
        getCategoryName(true);
    }, []);

    useEffect(() => {
        if (categoryOptions.length > 0) {
            fetchAllData();
            newArrivalData();
        }
    }, [categoryOptions]); // Fetch items when categoryOptions are available

    const newArrivalData = async () => {
        setNewArrival(await handleSearch('all'));
    };

    return (
        <>
            <Navbar />
            <Hero />
            <Section2 />
            {/* <Section2 /> */}
            {/* <CarouselComp /> */}
            <Section3
                hd={'New Arrival'}
                data={newArrival}
                btnLink={`/collection/${'New Arrival'.replaceAll(' ', '_')}`}
            />
            {
                data.map((v, i) => {
                    return (
                        v && v.length > 0 ?
                            <Section3
                                key={i}
                                hd={v[0].category}
                                data={v}
                                btnLink={`/collection/${v[0].category.replaceAll(' ', '_')}`}
                            /> : ''
                    )
                })
            }
            <Testimonials setShowLoader={setShowSpinner} />
            <MoveToTop />
            {/* <AboutSec /> */}
            <Footer />
        </>
    );
};

export default Home;